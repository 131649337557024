<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4" class="mt-0">
      <v-row v-if="data.updated_products">
        <v-col cols="12">
          <v-alert type="success" dense outlined class="pa-2 mb-0 mx-2">
            {{ data.updated_products }} products updated <br />
            {{ data.created_products }} products created with status new
          </v-alert>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-container>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-autocomplete
                    dense
                    autofocus
                    hide-details
                    outlined
                    :label="$t('hub')"
                    v-model="params.hub_id"
                    :items="listHubs"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-btn
                    block
                    outlined
                    color="primary"
                    @click="importExpressPorducts(params)"
                    :disabled="!is_valid"
                    :loading="isLoading"
                    >{{ $t("import_express_products") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  async mounted() {
    try {
      await this.$store.dispatch("hubs/list");
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  computed: {
    ...mapGetters({
      // isLoading: 'syncProducts/isLoading',
      data: "syncProducts/data",
      isLoadingHubs: "hubs/isLoadingHubs",

      listHubs: "hubs/listHubs",
    }),
  },
  data() {
    return {
      params: {},
      is_valid: true,
      isLoading: false,
    };
  },
  methods: {
    async importExpressPorducts(params) {
      this.isLoading = true;
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("syncProducts/syncWithExpress", params);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.$refs.form.reset();
          this.isLoading = false;
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
